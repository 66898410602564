<template>
    <div>
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('company_name')">
                    <div class="label-as-input">
                        {{ isNotNullValue(formData.name) }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('company_name_en')">
                    <div class="label-as-input">
                        {{ isNotNullValue(formData.name_en) }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('scholarship_type')">
                    <div class="label-as-input">
                        {{ isNotNullValue($t(formData.type)) }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('scholarship_rate')">
                    <div class="label-as-input">
                        {{ isNotNullValue('%' + formData.rank) }}
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import CoopPublicScholarshipService from '@/services/CoopPublicScholarshipService'

export default {
    components: {
        CoopPublicScholarshipService
    },
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            formData: {},
            formLoading: false
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            CoopPublicScholarshipService.get(id)
                .then(response => {
                    this.formData = response.data.data
                })
                .catch((error) => {
                    this.$toast.error(this.$t('api.' + error.data.message))
                })
        }

    }
}
</script>
